// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/Download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/Home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-partner-js": () => import("./../../../src/pages/Partner.js" /* webpackChunkName: "component---src-pages-partner-js" */),
  "component---src-pages-request-online-demo-js": () => import("./../../../src/pages/RequestOnlineDemo.js" /* webpackChunkName: "component---src-pages-request-online-demo-js" */),
  "component---src-pages-request-pin-js": () => import("./../../../src/pages/RequestPin.js" /* webpackChunkName: "component---src-pages-request-pin-js" */)
}

